import Modal from "./Modal";
import { ACTIONS } from "../context/designs_context";
import designs from "../data_structures/designs";
import Icons from "../img/icons/icons";
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from "react";
import { useDesignsContext } from "../hooks/use_designs_context_hook";
import { ImageWithBlurBackground } from "../utils/functions";
import { ModalContext } from "../context/modal_context";

import { isMobileDevice, isSmallScreen } from "../utils/functions";

/*
  TODO: change styling/classes for mobile so that the expand Icon is always on pictures and hover element doesn't exist
*/

const ITEMS_PER_PAGE = isMobileDevice() && isSmallScreen() ? 10 : 12;

const DesignGallery = () => {
  console.log("rendering DesignGallery...");

  const [visibleItemsCount, setVisibleItemsCount] = useState(ITEMS_PER_PAGE);
  const { openModal } = useContext(ModalContext);

  const { index, img, dispatch } = useDesignsContext();
  const designState = { index: index, img: img };

  // fires function when component is rendered (only once because of empty dependency array)
  // Initiates Design state to null
  useEffect(() => {
    dispatch({ type: ACTIONS.SET_DESIGN_NULL, payload: {} });
  }, [dispatch]);

  useEffect(() => {}, []);

  const loadMore = useCallback(() => {
    setVisibleItemsCount((prevCount) => prevCount + ITEMS_PER_PAGE);
  }, []);

  // TODO: move this to helper functions
  const expandImage = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      let targetElement = e.target;
      if (targetElement.tagName === "svg") {
        targetElement = targetElement.parentElement;
      }
      const designIndexValue = targetElement.getAttribute("design_value");

      if (designIndexValue === null) {
        console.log("design index value not yet set");
      } else {
        dispatch({
          type: ACTIONS.SET_DESIGN,
          payload: { index: designIndexValue },
        });
        openModal();
      }
    },
    [dispatch, openModal]
  );

  const sortedDesigns = useMemo(() => {
    return [...designs].sort((a, b) => a.value - b.value);
  }, []);

  return (
    <div className="container">
      <div className="gallery-component-container">
        <Modal
          state={designState}
          dispatch={dispatch}
          nextAction={ACTIONS.SET_DESIGN_NEXT}
          prevAction={ACTIONS.SET_DESIGN_PREV}
          nullAction={ACTIONS.SET_DESIGN_NULL}
          dataStyles={sortedDesigns}
          img_param={img} // ? remove this?
          source="design"
          close={() => dispatch({ type: ACTIONS.SET_TAT_NULL, payload: {} })}
        />{" "}
        <div className="image-gallery design-gallery slide-up-grid">
          {sortedDesigns.slice(0, visibleItemsCount).map((design) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              key={design.value}
              design_value={design.value}
              onClick={expandImage}
              className="shadow-under"
            >
              <ImageWithBlurBackground img={design} />
              <Icons.ExpandIcon />
            </a>
          ))}
        </div>
        {visibleItemsCount < designs.length && ( // Only show the button if there are more items to load
          <div className="load-more-container">
            <button onClick={loadMore} className="text-shadow">
              {/* <h3> See More </h3>
              <div className="load-more-dots">
                <div className="big-dots">. . .</div>
              </div> */}

              <h3 className="load-more-text">+</h3>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DesignGallery;
