import { Swiper, SwiperSlide } from "swiper/react";
import { ImageWithBlurBackground } from "../utils/functions";
// import Swiper from "swiper";

import {
  Autoplay,
  EffectCoverflow,
  Pagination,
  Mousewheel,
  Keyboard,
} from "swiper";
import tats from "../data_structures/tats";


/*
  TODO: change styling so pictures don't distort
*/

/*
  Swiper will be depricated with React. Going to need to change these
  https://swiperjs.com/demos#effect-coverflow
*/

/*
  TODO: - Add meta tags
  TODO: - Make it abundantly clear it's in philly and tattooing
*/

const HomeSwiper = () => {
  return (
    <div>
      <div className="swiper-container">
        <div className="home-swiper slide-in-ecliptic">
          <>
            <Swiper
              tabIndex={0}
              effect={"coverflow"}
              grabCursor={true}
              autoplay={{
                delay: 3300,
                disableOnInteraction: false,
              }}
              mousewheel={true}
              keyboard={{ enabled: true }}
              centeredSlides={true}
              loop={false}
              spaceBetween={40} /* TODO: Ask Andy about distortion */
              slidesPerView={5}
              coverflowEffect={{
                rotate: 10,
                stretch: 1,
                depth: 100,
                modifier: 1,
                slideShadows: false,
              }}
              pagination={false}
              // Maybe you have to put Component into focus
              // Watch that react video
              modules={[
                Mousewheel,
                Autoplay,
                EffectCoverflow,
                Pagination,
                Keyboard,
              ]}
              className="mySwiper"
            >
              {tats.sort((a, b) => a.value - b.value).map((tat, index) => {
                if (tat.homeWheel) {
                  return (
                    <SwiperSlide key={index}>
                      <div className="shadow-host">
                        <ImageWithBlurBackground img={tat} />
                      </div>
                      {/* <img src={tat.img} alt={`Tattoo ${tat.value}`} /> */}
                    </SwiperSlide>
                  );
                }
                return null;
              })}
            </Swiper>
          </>
        </div>
      </div>
    </div>
  );
};

export default HomeSwiper;
