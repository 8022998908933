import React from "react";

import { useState, useEffect } from "react";

export const ModalContext = React.createContext({
  isModalOpen: false,
  openModal: () => {},
  closeModal: () => {}
});

// Create a provider component
export const ModalContextProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Functions to manipulate the modal state
  const openModal = () => {
    setIsModalOpen(true);
    disableScroll();
  };

  const closeModal = () => {
    setIsModalOpen(false);
    enableScroll();
  };

  const disableScroll = () => {
    document.body.classList.add('no-scroll');
  };

  const enableScroll = () => {
    document.body.classList.remove('no-scroll');
  };

  useEffect(() => {
    return () => enableScroll();
  }, []);

  return (
    <ModalContext.Provider value={{ isModalOpen, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};