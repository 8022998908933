import Icons from "../img/icons/icons";

const Footer = () => {
  console.log("rendering footer");

  return (
    <div className="footer">
      <div className="container">
        <div className="flex-horizontal-container">
          <a href="mailto:&#97;&#110;&#100;&#114;&#101;&#119;&#104;&#97;&#114;&#114;&#105;&#115;&#57;&#50;&#52;&#64;&#103;&#109;&#97;&#105;&#108;&#46;&#99;&#111;&#109;" aria-label="Call us" className="roll-in-left">
            {/* <Icons.PhoneIcon /> */}
            <Icons.MailIcon />
          </a>

          <a
            href="https://www.tiktok.com/@andysfiles?_t=8hRv81PQJuo&_r=1"
            target="_blank"
            aria-label="https://www.tiktok.com/@andysfiles?_t=8hRv81PQJuo&_r=1 (opens in a new tab)"
            rel="noreferrer"
            className="slide-in-ecliptic"
          >
            <Icons.TikTokIcon />
          </a>

          <a
            href="https://www.instagram.com/andyhtattoo/"
            target="_blank"
            aria-label="https://www.instagram.com/andyhtattoo/ (opens in a new tab)"
            rel="noreferrer"
            className="roll-in-right"
          >
            <Icons.InstagramIcon />
          </a>

        </div>
        <div >
          <h3 className="text-shadow track-in">Philadelphia, PA</h3>
        </div>
      </div>
    </div>
  );
};

export default Footer;
