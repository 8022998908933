import { createContext, useReducer } from "react";
import { useEffect } from "react";
import tats from "../data_structures/tats";
import { getImgByValue } from "../utils/functions";
import { getNextTatIndex, getPrevTatIndex } from "../utils/functions";

/* 
  TODO:
    - change the naming convetions with State variables. Maybe use STATES
*/

export const TatsContext = createContext();

export const ACTIONS = {
  SET_TAT: "set_tat",
  SET_TAT_NULL: "set_tat_null",
  SET_TAT_NEXT: "set_tat_next",
  SET_TAT_PREV: "set_tat_prev",
};

export const STATES = {
  TAT_INDEX: "index",
  TAT_IMG: "img",
};

// Use reducer: https://www.youtube.com/watch?v=kK_Wqx3RnHk
// state is the previous state (second argument in useReducer, whatever it currently is)
// action is the object we pass into the dispatch function
export const tatsReducer = (state, action) => {
  const newIndex = action.payload.index ? action.payload.index : null;
  console.log(
    `Tats reducer action: ${action.type} || Payload: index: ${newIndex}`
  );

  switch (action.type) {
    case ACTIONS.SET_TAT:
      // TODO: Added this below after adding blurrd image, would be better if image still could be expanded even if it hasn't loaded
      if (newIndex === null) {
        return {
          index: null,
          img: null,
        };
      }
      var tat = getImgByValue(tats, newIndex);
      return {
        ...state,
        index: newIndex,
        img: tat,
      };

    case ACTIONS.SET_TAT_NULL:
      return {
        index: null,
        img: null,
      };

    case ACTIONS.SET_TAT_NEXT:
      console.log("SETTING TAT to NEXT");
      var nextTatIndex = getNextTatIndex(newIndex);
      var nextImg = getImgByValue(tats, nextTatIndex);
      // left off here
      return {
        index: nextTatIndex,
        img: nextImg,
      };
    case ACTIONS.SET_TAT_PREV:
      console.log("SETTING TAT to PREV");
      var prevTatIndex = getPrevTatIndex(newIndex);
      var prevImg = getImgByValue(tats, prevTatIndex);

      return {
        index: prevTatIndex,
        img: prevImg,
      };

    default:
      return state;
  }
};

// children property represents whatever component/template TatsContextProvider wraps
export const TatsContextProvider = ({ children }) => {
  // useReducer similar to useState, but the way the state is updated is different

  const [state, dispatch] = useReducer(tatsReducer, {
    index: null,
    tat: null, // What is this tat? should I remove it?
  });

  useEffect(() => {
    console.log("State has changed in tats context:", state);
  }, [state]);

  return (
    <TatsContext.Provider value={{ ...state, dispatch }}>
      {children}
    </TatsContext.Provider>
  );
};

/*
TODO
  - change SET_TAT_NEXT and SET_TAT_PREV to work for the working tat-list
      - Now this may not be an issue, there will just be images shown that aren't in the gallery
*/
