import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Icons from "../img/icons/icons";
import { isMobileDevice } from "../utils/functions";
import {
  EffectCoverflow,
  Pagination,
  Mousewheel,
  Keyboard,
  Lazy,
} from "swiper";
import { ImageWithBlurBackground } from "../utils/functions";
import { ModalContext } from "../context/modal_context";

const Modal = ({
  state = {},
  dispatch,
  nextAction,
  prevAction,
  nullAction,
  dataStyles,
  img_param,
  source,
}) => {
  const [isMobile, setIsMobile] = useState(isMobileDevice);
  const swiperRef = useRef(null);
  const { index } = state;
  const { closeModal } = useContext(ModalContext);
  const [swiperInitialized, setSwiperInitialized] = useState(false);  // This is used so hand animation is only shown on first image opened
  const [openedImg, setOpenedImg] = useState(null); // TODO: remove this, not needed
  const modalClassName =
    source === "design" ? "modal-overlay no-select design-modal" : "modal-overlay no-select";

  const HandIcon =
    source === "design" ? Icons.HandIconBlack : Icons.HandIconWhite;

  // Handle window resize
  useEffect(() => {
    function handleResize() {
      setIsMobile(isMobileDevice);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

/*
  FYI: useState is designed to provide a stateful value and a function to update it.
  State is important because it allows you to have values that persist across re-renders and can cause a re-render when changed
*/
  const [prevSlidePos, setPrevSlidePos] = useState(undefined);

  const handleSlideChange = () => {
    /*
      TODO:
        - dig into this, not very clean IMO
    */
    if (swiperRef.current.swiper.activeIndex) {
      const currentSlidePos = swiperRef.current.swiper.activeIndex;
      console.log("Current Slide Position: ", currentSlidePos);
      console.log("Previous Slide Pos: ", prevSlidePos);

      if (prevSlidePos === undefined) {
        setPrevSlidePos(currentSlidePos);
      }
      // Slide changed in the 'next' direction
      if (currentSlidePos > prevSlidePos) {
        nextImage();
      }
      // Slide changed in the 'prev' direction
      else if (currentSlidePos < prevSlidePos) {
        prevImage();
      }

      setPrevSlidePos(currentSlidePos); // Update the previous slide index for the next change
    }
  };

  const nextImage = useCallback((e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    dispatch({ type: nextAction, payload: { index } });
  }, [dispatch, index, nextAction]);

  const prevImage = useCallback((e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    dispatch({ type: prevAction, payload: { index } });
  }, [dispatch, index, prevAction]);


  const handleKeyDown = useCallback((e) => {
    // Check if the left arrow key is pressed
    if (e.key === 'ArrowLeft') {
      e.preventDefault(); // Prevent the default action
      prevImage(); // Call the function to show the previous image
    }
    // Check if the right arrow key is pressed
    else if (e.key === 'ArrowRight') {
      e.preventDefault(); // Prevent the default action
      nextImage(); // Call the function to show the next image
    }}, [nextImage, prevImage]);
  

  const handleSwiperInit = (index) => {
    setSwiperInitialized(true);
    setOpenedImg(index);
    console.log("Swiper initialized: ", openedImg)
  }

  const handleModalClose = () => {
    setSwiperInitialized(false);
    setOpenedImg(null);
    console.log("Swiper Closed: ", openedImg)
  }

  // Handle window resize
  useEffect(() => {
    function handleResize() {
      setIsMobile(isMobileDevice);
    }
    window.addEventListener("resize", handleResize);
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [prevImage, nextImage, handleKeyDown]);

  if (!img_param) {
    closeModal(); // Controls no-scroll. Vertical scrolling allowed when modal is closed
    return null;
  }

  if (isMobile) {
    // Swiper layout for mobile
    return (
      <div
        className="modal-overlay"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation(); // prevents parent component modalContext from noticing the event
          dispatch({ type: nullAction, payload: {} });
          setPrevSlidePos(undefined);
          handleModalClose();
        }}
      >
        <div className="modal-swiper">
          <div className="modal-content">
            <div className="image-container">
              <Swiper
                effect={"coverflow"}
                ref={swiperRef}
                spaceBetween={50}
                slidesPerView={3}
                loop={true}
                initialSlide={index - 2} // adjusting for list elements starting with 0 and having 3 slidesPerView. Allows clicked image be actualy image
                onSlideChange={handleSlideChange}
                onInit={() => handleSwiperInit(index)}
                pagination={false}
                lazy={true} // Not perfect but definitely helps
                modules={[
                  EffectCoverflow,
                  Pagination,
                  Mousewheel,
                  Keyboard,
                  Lazy,
                ]}
              >
                {dataStyles.sort((a, b) => a.value - b.value).map((obj, slideIndex) => {
                  return (
                    <SwiperSlide key={slideIndex}>
                      {swiperInitialized && slideIndex === openedImg - 1 && (
                        <div className="swipe-icon show-swipe">
                          <HandIcon />
                        </div>
                      )}
                      <ImageWithBlurBackground img={obj} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              {/* {swiperLoaded && (
                <div className="fade-out no-opacity">
                  <h3>Swipe to see more</h3>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    // Original layout for larger screens
    return (
      <div
        className={modalClassName}
        onClick={(e) => {
          e.preventDefault();
          dispatch({ type: nullAction, payload: {} });
        }}
      >
        <div
          className="modal-content"
          onClick={(e) => {
            e.preventDefault();
            dispatch({ type: nullAction, payload: {} });
          }}
        >
          <div className="image-container">
            <div className="hover-area-left" onClick={prevImage}>
              <div className="arrow-icon">
                <Icons.LeftArrowIcon />
              </div>
            </div>
            <div className="hover-area-right" onClick={nextImage}>
              <div className="arrow-icon">
                <Icons.RightArrowIcon />
              </div>
            </div>
            <img
              src={img_param}
              alt="IMG"
              loading="lazy"
              draggable="false"
              className="no-select"
            />
          </div>
        </div>
      </div>
    );
  }
};

export default Modal;
