import React, { useEffect } from "react";
import { useTatsContext } from "../hooks/use_tats_context_hook";
import HomeSwiper from "../components/Home_Swiper";
import { ACTIONS } from "../context/tats_context";
import Layout from "../components/Layout";
/* 
  TODO:
*/

const Home = () => {
  console.log("rendering home...");
  const { dispatch } = useTatsContext();

  // fires function when component is rendered (only once because of empty dependency array)
  // Initiates Tat state to null
  useEffect(() => {
    dispatch({ type: ACTIONS.SET_TAT_NULL, payload: {} });
  }, [dispatch]);

  return (
    <Layout>
      <HomeSwiper />
    </Layout>
  );
};

export default Home;
