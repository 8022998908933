import { useRef, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Icons from "../img/icons/icons";
import Logo from "../img/logos/logo-w-600.png";
import Book from "../img/logos/book.png";

const Navbar = () => {
  console.log("rendering navbar");
  const navRef = useRef();
  const location = useLocation();
  const [showBookIcon, setShowBookIcon] = useState(false)

  useEffect(() => {
    setShowBookIcon(location.pathname !== "/booking");
  }, [location]);

  const showNavbar = () => {
    console.log("Entering side-nav");
    if (navRef.current) {
      navRef.current.classList.toggle("side-nav");
    }
  };

  const hideNavbar = () => {
    console.log("Leaving side-nav");
    if (navRef.current) {
      navRef.current.classList.remove("side-nav");
    }
  };

  return (
    <header>
      <div className="container">
        <nav ref={navRef} className="text-shadow vertical-align">
          {/* <div className="entire-header"> */}
          {/* <div className="title-img-header flex-horizontal-container">
              <div className="nav-logo"></div>
            </div> */}
          {/* <div className="spacer"></div> */}
          <button className="nav-btn" onClick={showNavbar}>
            <Icons.MenuIcon />
          </button>

          <ul className="track-in">
            <button className="nav-btn nav-close-btn" onClick={showNavbar}>
              <Icons.MenuIcon />
            </button>
            <li>
              <Link to="/" onClick={hideNavbar}>
                home
              </Link>
            </li>
            <li>
              <Link to="/flash" onClick={hideNavbar}>
                flash
              </Link>
            </li>
            <li>
              <Link to="/gallery" onClick={hideNavbar}>
                gallery
              </Link>
            </li>
            <li>
              <Link to="/booking" onClick={hideNavbar}>
                booking
              </Link>
            </li>
          </ul>
          <Link to="/" onClick={hideNavbar}>
            <img src={Logo} className="logo bounce-in-top" alt="logo" />
          </Link>
          {location.pathname !== "/booking" && (
            <Link to="/booking" onClick={hideNavbar}>
              <img
                src={Book}
                className={`book-img ${showBookIcon ? "book-img-visible" : ""}`}
                alt="book"
              />
            </Link>
          )}
          {/* </div> */}
        </nav>
      </div>
    </header>
  );
};

export default Navbar;

/* NOTES
  - responsive: https://www.youtube.com/watch?v=amf18mxNX18
*/
