import Modal from "./Modal";
import { ACTIONS } from "../context/tats_context";
import tats from "../data_structures/tats";
import Icons from "../img/icons/icons";
import React, { useEffect, useState, useContext } from "react";
import { useTatsContext } from "../hooks/use_tats_context_hook";

import { isSmallScreen, ImageWithBlurBackground } from "../utils/functions";
import { ModalContext } from "../context/modal_context";

// import { isMobileDevice } from "../utils/functions";

/*
  TODO: change styling/classes for mobile so that the expand Icon is always on pictures and hover element doesn't exist
*/

const ITEMS_PER_PAGE = 25;

const TattooGallery = () => {
  console.log("rendering home...");
  const { index, img, dispatch } = useTatsContext();
  const [isMobileView, setIsMobileView] = useState(isSmallScreen());
  const tatState = { index: index, img: img };
  const [visibleItemsCount, setVisibleItemsCount] = useState(ITEMS_PER_PAGE);
  const { openModal } = useContext(ModalContext);

  // const expandIconClass = isMobileDevice() ? "is-mobile" : "is-not-mobile";

  // fires function when component is rendered (only once because of empty dependency array)
  // Initiates Tat state to null
  useEffect(() => {
    dispatch({ type: ACTIONS.SET_TAT_NULL, payload: {} });
    // ? add ACTIONS.SET_DESIGNS_NULL?
  }, [dispatch]);

  const loadMore = () => {
    setVisibleItemsCount((prevCount) => prevCount + ITEMS_PER_PAGE);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(isSmallScreen());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // TODO: move this to helper functions or at least align with DesignGallery
  const expandImage = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let targetElement = e.target;
    if (targetElement.tagName === "svg") {
      targetElement = targetElement.parentElement;
    }

    const tatIndexValue = targetElement.getAttribute("tat_value");

    if (tatIndexValue === null) {
      console.log("tat index value not yet set");
    } else {
      openModal();
      dispatch({ type: ACTIONS.SET_TAT, payload: { index: tatIndexValue } });
    }
  };

  const renderImages = () => {
    const images = isMobileView
      ? tats.sort((a, b) => a.value - b.value).slice(0, visibleItemsCount)
      : tats;

    return images.map((img, index) => (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        key={index}
        className={`${img.className}`}
        tat_value={img.value}
        onClick={expandImage}
      >
        <ImageWithBlurBackground img={img} />
        {/* <div className={expandIconClass}> */}
        <Icons.ExpandIcon />
        {/* </div> */}
      </a>
    ));
  };

  return (
    <div className="container">
      {/* <div className="gallery-container" */}
      <div className="gallery-component-container">
        <Modal
          state={tatState}
          dispatch={dispatch}
          nextAction={ACTIONS.SET_TAT_NEXT}
          prevAction={ACTIONS.SET_TAT_PREV}
          nullAction={ACTIONS.SET_TAT_NULL}
          dataStyles={tats}
          img_param={img} // ? remove this?
          close={() => dispatch({ type: ACTIONS.SET_TAT_NULL, payload: {} })}
        />{" "}
        <div className="image-gallery tats-gallery slide-up-grid">
          {renderImages()}
        </div>
        {visibleItemsCount < tats.length &&
          isMobileView && ( // Only show the button if there are more items to load
            // <div className="load-more-container">
            //   <button onClick={loadMore} className="load-more-button">
            //     <div className="big-dots">. . .</div>
            //   </button>
            // </div>
            <div className="load-more-container">
              <button onClick={loadMore} className="text-shadow">
                {/* <h3> See More </h3>
              <div className="load-more-dots">
                <div className="big-dots">. . .</div>
              </div> */}

                <h3 className="load-more-text">+</h3>
              </button>
            </div>
          )}
      </div>
    </div>
  );
};

export default TattooGallery;
