import tat1 from "../img/tattoos/tattoo-1.jpg";
import tat2 from "../img/tattoos/tattoo-2.jpg";
import tat3 from "../img/tattoos/tattoo-3.jpg";
import tat4 from "../img/tattoos/tattoo-4.jpg";
import tat5 from "../img/tattoos/tattoo-5.jpg";
import tat6 from "../img/tattoos/tattoo-6.jpg";
import tat7 from "../img/tattoos/tattoo-7.jpg";
import tat8 from "../img/tattoos/tattoo-8.jpg";
import tat9 from "../img/tattoos/tattoo-9.jpg";
import tat10 from "../img/tattoos/tattoo-10.jpg";
import tat11 from "../img/tattoos/tattoo-11.jpg";
import tat12 from "../img/tattoos/tattoo-12.jpg";
import tat13 from "../img/tattoos/tattoo-13.jpg";
import tat14 from "../img/tattoos/tattoo-14.jpg";
import tat15 from "../img/tattoos/tattoo-15.jpg";
import tat16 from "../img/tattoos/tattoo-16.jpg";
import tat17 from "../img/tattoos/tattoo-17.jpg";
import tat18 from "../img/tattoos/tattoo-18.jpg";
import tat19 from "../img/tattoos/tattoo-19.jpg";
import tat20 from "../img/tattoos/tattoo-20.jpg";
import tat21 from "../img/tattoos/tattoo-21.jpg";
import tat22 from "../img/tattoos/tattoo-22.jpg";
import tat23 from "../img/tattoos/tattoo-23.jpg";
import tat24 from "../img/tattoos/tattoo-24.jpg";
import tat25 from "../img/tattoos/tattoo-25.jpg";
import tat26 from "../img/tattoos/tattoo-26.jpg";
import tat27 from "../img/tattoos/tattoo-27.jpg";
import tat28 from "../img/tattoos/tattoo-28.jpg";
import tat29 from "../img/tattoos/tattoo-29.jpg";
import tat30 from "../img/tattoos/tattoo-30.jpg";
import tat31 from "../img/tattoos/tattoo-31.jpg";
import tat32 from "../img/tattoos/tattoo-32.jpg";
import tat33 from "../img/tattoos/tattoo-33.jpg";
import tat34 from "../img/tattoos/tattoo-34.jpg";
import tat35 from "../img/tattoos/tattoo-35.jpg";
import tat36 from "../img/tattoos/tattoo-36.jpg";
import tat37 from "../img/tattoos/tattoo-37.jpg";
import tat38 from "../img/tattoos/tattoo-38.jpg";
import tat39 from "../img/tattoos/tattoo-39.jpg";
import tat40 from "../img/tattoos/tattoo-40.jpg";

const tats = [
  { className: "img-1", value: 36, img: tat1, description: "Girl neck star" },
  { className: "img-2", value: 2, img: tat2, description: "Deer skull" },
  { className: "img-3", value: 3, img: tat3, description: "Eye and eyebrow" },
  { className: "img-4", value: 4, img: tat4, description: "Bunny" },
  {
    className: "img-5",
    value: 11,
    img: tat5,
    description: "99",
    homeWheel: true,
  },
  { className: "img-6", value: 6, img: tat6, description: "Knife" },
  { className: "img-7", value: 7, img: tat7, description: "Wawa" },
  { className: "img-8", value: 8, img: tat8, description: "Shit!" },
  {
    className: "img-9",
    value: 30,
    img: tat9,
    description: "Globe face",
    homeWheel: true,
  },
  { className: "img-10", value: 10, img: tat10, description: "Lost" },
  {
    className: "img-11",
    value: 5,
    img: tat11,
    description: "3 girls hair",
    homeWheel: true,
  },
  { className: "img-12", value: 12, img: tat12, description: "Drippy eye" },
  { className: "img-13", value: 13, img: tat13, description: "Record player" },
  { className: "img-14", value: 14, img: tat14, description: "Jellyfish" },
  { className: "img-15", value: 15, img: tat15, description: "Partly heart" },
  {
    className: "img-16",
    value: 16,
    img: tat16,
    description: "Bumble bee",
    homeWheel: true,
  },
  { className: "img-17", value: 17, img: tat17, description: "small heart" },
  { className: "img-18", value: 18, img: tat18, description: "UFO" },
  { className: "img-19", value: 19, img: tat19, description: "Spiderman" },
  { className: "img-20", value: 39, img: tat20, description: "Squigly sword" },
  { className: "img-21", value: 21, img: tat21, description: "Leaf" },
  { className: "img-22", value: 22, img: tat22, description: "Knife Eyes" },
  {
    className: "img-23",
    value: 9,
    img: tat23,
    description: "Tiger",
    homeWheel: true,
  },
  { className: "img-24", value: 24, img: tat24, description: "13" },
  { className: "img-25", value: 25, img: tat25, description: "Eye wings" },
  { className: "img-26", value: 26, img: tat26, description: "Little flower" },
  { className: "img-27", value: 27, img: tat27, description: "Cat House" },
  { className: "img-28", value: 28, img: tat28, description: "Cool hands" },
  {
    className: "img-29",
    value: 29,
    img: tat29,
    description: "Dragonfly",
    homeWheel: true,
  },
  {
    className: "img-30",
    value: 1,
    img: tat30,
    description: "Alex Flower",
    homeWheel: true,
  },
  { className: "img-31", value: 31, img: tat31, description: "Azul arm" },
  { className: "img-32", value: 32, img: tat32, description: "Azul face" },
  { className: "img-33", value: 33, img: tat33, description: "Lemon" },
  { className: "img-34", value: 34, img: tat34, description: "Jodi Flower" },
  {
    className: "img-35",
    value: 35,
    img: tat35,
    description: "Frog Smoking",
    homeWheel: true,
  },
  {
    className: "img-36",
    value: 23,
    img: tat36,
    description: "Heart Cross",
    homeWheel: true,
  },
  {
    className: "img-37",
    value: 37,
    img: tat37,
    description: "Happy vase sad vase",
  },
  { className: "img-38", value: 38, img: tat38, description: "Castle moat" },
  { className: "img-39", value: 20, img: tat39, description: "Flag man" },
  {
    className: "img-40",
    value: 40,
    img: tat40,
    description: "Calvin and Hobbes",
    homeWheel: true,
  },
];

export default tats;
