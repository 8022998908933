import React from "react";
import ReactDOM from "react-dom/client";
import "swiper/swiper.min.css";
import "./css/spacing.css"
import "./css/animation.css";
import "./css/img.css";
import "./css/components.css";
import "./css/main.css";
import App from "./App";
import { TatsContextProvider } from "./context/tats_context";
import { DesignsContextProvider } from "./context/designs_context";
import { ModalContextProvider } from "./context/modal_context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  /* 
    React.StrictMode helps identify side-effects in your components, also triggers everything 2x toggle on and off in dev
    Probably turn off for production, less optimal
  */
  // <React.StrictMode>
  <ModalContextProvider>
    <TatsContextProvider>
      <DesignsContextProvider>
        <App />
      </DesignsContextProvider>
    </TatsContextProvider>
  </ModalContextProvider>
  // </React.StrictMode>
);
