import React from "react";

const LeftArrowIcon = () => (
  <svg
    className="left-arrow"
    xmlns="https://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="48"
      d="M328 112L184 256l144 144"
    />
  </svg>
);

const RightArrowIcon = () => (
  <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="48"
      d="M184 112l144 144-144 144"
    />
  </svg>
);

const InstagramIcon = () => (
  <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 512 512" className="ionicon svg-shadow">
    <path
      strokeMiterlimit="10"
      fill="black"
      strokeWidth="32"
      stroke="currentColor"
      d="M349.33 69.33a93.62 93.62 0 0193.34 93.34v186.66a93.62 93.62 0 01-93.34 93.34H162.67a93.62 93.62 0 01-93.34-93.34V162.67a93.62 93.62 0 0193.34-93.34h186.66m0-37.33H162.67C90.8 32 32 90.8 32 162.67v186.66C32 421.2 90.8 480 162.67 480h186.66C421.2 480 480 421.2 480 349.33V162.67C480 90.8 421.2 32 349.33 32z"
    />
    <path
      strokeMiterlimit="10"
      fill="black"
      strokeWidth="32"
      stroke="currentColor"
      d="M377.33 162.67a28 28 0 1128-28 27.94 27.94 0 01-28 28zM256 181.33A74.67 74.67 0 11181.33 256 74.75 74.75 0 01256 181.33m0-37.33a112 112 0 10112 112 112 112 0 00-112-112z"
    />
  </svg>
);

const TikTokIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="ionicon svg-shadow"
    viewBox="0 0 512 512"
  >
    <path
      strokeMiterlimit="10"
      fill="black"
      strokeWidth="32"
      stroke="currentColor"
      d="M412.19 118.66a109.27 109.27 0 01-9.45-5.5 132.87 132.87 0 01-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14 23.9 350 16 350.13 16h-82.44v318.78c0 4.28 0 8.51-.18 12.69 0 .52-.05 1-.08 1.56 0 .23 0 .47-.05.71v.18a70 70 0 01-35.22 55.56 68.8 68.8 0 01-34.11 9c-38.41 0-69.54-31.32-69.54-70s31.13-70 69.54-70a68.9 68.9 0 0121.41 3.39l.1-83.94a153.14 153.14 0 00-118 34.52 161.79 161.79 0 00-35.3 43.53c-3.48 6-16.61 30.11-18.2 69.24-1 22.21 5.67 45.22 8.85 54.73v.2c2 5.6 9.75 24.71 22.38 40.82A167.53 167.53 0 00115 470.66v-.2l.2.2c39.91 27.12 84.16 25.34 84.16 25.34 7.66-.31 33.32 0 62.46-13.81 32.32-15.31 50.72-38.12 50.72-38.12a158.46 158.46 0 0027.64-45.93c7.46-19.61 9.95-43.13 9.95-52.53V176.49c1 .6 14.32 9.41 14.32 9.41s19.19 12.3 49.13 20.31c21.48 5.7 50.42 6.9 50.42 6.9v-81.84c-10.14 1.1-30.73-2.1-51.81-12.61z"
    />
  </svg>
);

const PhoneIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="ionicon"
    viewBox="0 0 512 512"
  >
    <path
      d="M451 374c-15.88-16-54.34-39.35-73-48.76-24.3-12.24-26.3-13.24-45.4.95-12.74 9.47-21.21 17.93-36.12 14.75s-47.31-21.11-75.68-49.39-47.34-61.62-50.53-76.48 5.41-23.23 14.79-36c13.22-18 12.22-21 .92-45.3-8.81-18.9-32.84-57-48.9-72.8C119.9 44 119.9 47 108.83 51.6A160.15 160.15 0 0083 65.37C67 76 58.12 84.83 51.91 98.1s-9 44.38 23.07 102.64 54.57 88.05 101.14 134.49S258.5 406.64 310.85 436c64.76 36.27 89.6 29.2 102.91 23s22.18-15 32.83-31a159.09 159.09 0 0013.8-25.8C465 391.17 468 391.17 451 374z"
      fill="black"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="32"
    />
  </svg>
);

const MenuIcon = () => (
  <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="32"
      d="M80 160h352M80 256h352M80 352h352"
    />
  </svg>
);

const ExpandIcon = () => (
  <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
      d="M432 320v112H320M421.8 421.77L304 304M80 192V80h112M90.2 90.23L208 208M320 80h112v112M421.77 90.2L304 208M192 432H80V320M90.23 421.8L208 304"
    />
  </svg>
);

const HandIconWhite = () => (
  <svg version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="white"
      stroke="yellow"
      d="m432 72c-52.77 0-96 43.23-96 96v364.88c-54.203 11.52-96 57.586-96 115.12v96c0 59.902 33.176 119.51 72 173.25 36.277 50.215 77.844 93.707 108 124.12v62.625c0 12.566 11.434 24 24 24h408c12.566 0 24-11.434 24-24v-123.75c51.824-55.715 84-130.09 84-212.25v-156c0-52.77-43.23-96-96-96-18.492 0-34.801 6.582-49.5 15.75-4.3828-48.793-44.625-87.75-94.5-87.75-18.492 0-34.801 6.582-49.5 15.75-4.3828-48.793-44.625-87.75-94.5-87.75-17.824 0-33.652 6.0586-48 14.625v-218.62c0-52.77-43.23-96-96-96zm0 48c27.008 0 48 20.992 48 48v300c0 13.254 10.746 24 24 24s24-10.746 24-24c0-27.008 20.992-48 48-48s48 20.992 48 48v72c0 13.254 10.746 24 24 24s24-10.746 24-24c0-27.008 20.992-48 48-48s48 20.992 48 48v72c0 13.254 10.746 24 24 24s24-10.746 24-24c0-27.008 20.992-48 48-48s48 20.992 48 48v156c0 73.371-29.191 139.07-76.875 186.75-4.543 4.4922-7.1719 10.859-7.125 17.25v108h-360v-48c0.050781-6.3906-2.582-12.758-7.125-17.25-29.656-29.656-73.699-75.555-109.88-125.62-36.176-50.074-63-104.29-63-145.12v-96c0-31.863 19.789-58.18 48-67.875v139.88c-0.17969 12.68 11.32 24.344 24 24.344s24.18-11.664 24-24.344v-552c0-27.008 20.992-48 48-48z"
    />
  </svg>
);

const HandIconBlack = () => (
  <svg version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="black"
      stroke="yellow"
      d="m432 72c-52.77 0-96 43.23-96 96v364.88c-54.203 11.52-96 57.586-96 115.12v96c0 59.902 33.176 119.51 72 173.25 36.277 50.215 77.844 93.707 108 124.12v62.625c0 12.566 11.434 24 24 24h408c12.566 0 24-11.434 24-24v-123.75c51.824-55.715 84-130.09 84-212.25v-156c0-52.77-43.23-96-96-96-18.492 0-34.801 6.582-49.5 15.75-4.3828-48.793-44.625-87.75-94.5-87.75-18.492 0-34.801 6.582-49.5 15.75-4.3828-48.793-44.625-87.75-94.5-87.75-17.824 0-33.652 6.0586-48 14.625v-218.62c0-52.77-43.23-96-96-96zm0 48c27.008 0 48 20.992 48 48v300c0 13.254 10.746 24 24 24s24-10.746 24-24c0-27.008 20.992-48 48-48s48 20.992 48 48v72c0 13.254 10.746 24 24 24s24-10.746 24-24c0-27.008 20.992-48 48-48s48 20.992 48 48v72c0 13.254 10.746 24 24 24s24-10.746 24-24c0-27.008 20.992-48 48-48s48 20.992 48 48v156c0 73.371-29.191 139.07-76.875 186.75-4.543 4.4922-7.1719 10.859-7.125 17.25v108h-360v-48c0.050781-6.3906-2.582-12.758-7.125-17.25-29.656-29.656-73.699-75.555-109.88-125.62-36.176-50.074-63-104.29-63-145.12v-96c0-31.863 19.789-58.18 48-67.875v139.88c-0.17969 12.68 11.32 24.344 24 24.344s24.18-11.664 24-24.344v-552c0-27.008 20.992-48 48-48z"
    />
  </svg>
);

const GreenCheckmark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="ionicon svg-shadow"
    viewBox="0 0 512 512"
  >
    <path
      d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
      fill="none"
      stroke="rgb(21, 255, 0)"
      stroke-miterlimit="10"
      stroke-width="32"
    />
    <path
      fill="none"
      stroke="rgb(21, 255, 0)"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="32"
      d="M352 176L217.6 336 160 272"
    />
  </svg>
);

const MailIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" class="ionicon svg-shadow" viewBox="0 0 512 512" >
    <rect
      x="48"
      y="96"
      width="416"
      height="320"
      rx="40"
      ry="40"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="32"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="32"
      d="M112 160l144 112 144-112"
    />
  </svg>
);

const Icons = {
  LeftArrowIcon,
  RightArrowIcon,
  InstagramIcon,
  TikTokIcon,
  PhoneIcon,
  MenuIcon,
  ExpandIcon,
  HandIconWhite,
  HandIconBlack,
  GreenCheckmark,
  MailIcon,
};

export default Icons;
