import { TatsContext } from '../context/tats_context'

import { useContext } from 'react'

export const useTatsContext = () => {
    const context = useContext(TatsContext)

    if (!context) {
        throw Error('useTatsContext must be used inside a TatsContextProviders')
    }

    return context
}