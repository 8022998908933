import { DesignsContext } from '../context/designs_context'

import { useContext } from 'react'

export const useDesignsContext = () => {
    const context = useContext(DesignsContext)

    if (!context) {
        throw Error('useDesignsContext must be used inside a DesignsContextProviders')
    }

    return context
}