import React from "react";
import DesignGallery from "../components/Design_Gallery";
import Layout from "../components/Layout";

/*
  
*/

const Designs = () => {
  console.log("rendering designs page...");

  return (
    <Layout> 
      <DesignGallery />
    </Layout>
  );
};

export default Designs;
