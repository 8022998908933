import React from "react";
import TattooGallery from "../components/Tattoo_Gallery";

import Layout from "../components/Layout";

/*
  TODO:
    - Isotope filter - css/js plugin that allows you to tag elements and easily
      sort them
*/

const Gallery = () => {
  console.log("rendering gallery page...");

  return (
    <Layout>
      <TattooGallery />
    </Layout>
  );
};

export default Gallery;
