import { BookingForm } from "../components/BookingForm";
import Layout from "../components/Layout";

/*
  TODO: try changing
*/

const Booking = () => {
  console.log("rendering Booking...");

  return (
    <div className="container">
      <Layout>
        <div className="booking-container text-shadow">
          {/* <h3 className="spacing-bottom">current rate: $75/hr</h3> */}
          <BookingForm />
          {/* <h3 className="spacing" >&#97;&#110;&#100;&#114;&#101;&#119;&#104;&#97;&#114;&#114;&#105;&#115;&#57;&#50;&#52;&#64;&#103;&#109;&#97;&#105;&#108;&#46;&#99;&#111;&#109;</h3> */}
        </div>
      </Layout>
    </div>
  );
};

export default Booking;
