import design1 from "../img/designs/design-1.jpg";
import design2 from "../img/designs/design-2.jpg";
import design3 from "../img/designs/design-3.jpg";
import design4 from "../img/designs/design-4.jpg";
import design5 from "../img/designs/design-5.jpg";
import design6 from "../img/designs/design-6.jpg";
import design7 from "../img/designs/design-7.jpg";
import design8 from "../img/designs/design-8.jpg";
import design9 from "../img/designs/design-9.jpg";
import design10 from "../img/designs/design-10.jpg";
import design11 from "../img/designs/design-11.jpg";
import design12 from "../img/designs/design-12.jpg";
import design13 from "../img/designs/design-13.jpg";
import design14 from "../img/designs/design-14.jpg";
import design15 from "../img/designs/design-15.jpg";
import design16 from "../img/designs/design-16.jpg";
import design17 from "../img/designs/design-17.jpg";
import design18 from "../img/designs/design-18.jpg";
import design19 from "../img/designs/design-19.jpg";
import design20 from "../img/designs/design-20.jpg";
import design21 from "../img/designs/design-21.jpg";
import design22 from "../img/designs/design-22.jpg";
import design23 from "../img/designs/design-23.jpg";
import design24 from "../img/designs/design-24.jpg";
import design25 from "../img/designs/design-25.jpg";
import design26 from "../img/designs/design-26.jpg";
import design27 from "../img/designs/design-27.jpg";
import design28 from "../img/designs/design-28.jpg";
import design29 from "../img/designs/design-29.jpg";
import design30 from "../img/designs/design-30.jpg";
import design31 from "../img/designs/design-31.jpg";
import design32 from "../img/designs/design-32.jpg";
import design33 from "../img/designs/design-33.jpg";
import design34 from "../img/designs/design-34.jpg";
import design35 from "../img/designs/design-35.jpg";
import design36 from "../img/designs/design-36.jpg";
import design37 from "../img/designs/design-37.jpg";
import design38 from "../img/designs/design-38.jpg";
import design39 from "../img/designs/design-39.jpg";
import design40 from "../img/designs/design-40.jpg";
import design41 from "../img/designs/design-41.jpg";
import design42 from "../img/designs/design-42.jpg";
import design43 from "../img/designs/design-43.jpg";
import design44 from "../img/designs/design-44.jpg";
import design45 from "../img/designs/design-45.jpg";
import design46 from "../img/designs/design-46.jpg";
import design47 from "../img/designs/design-47.jpg";
import design48 from "../img/designs/design-48.jpg";


const designs = [
  { className: 'design-1', value: 1, img: design1 },
  { className: 'design-2', value: 2, img: design2  },
  { className: 'design-3', value: 33, img: design3  },  /* Swap with  33 and 34*/
  { className: 'design-4', value: 34, img: design4  },
  { className: 'design-5', value: 5, img: design5  },
  { className: 'design-6', value: 6, img: design6  },
  { className: 'design-7', value: 7, img: design7  },
  { className: 'design-8', value: 8, img: design8  },
  { className: 'design-9', value: 9, img: design9  },
  { className: 'design-10', value: 10, img: design10  },
  { className: 'design-11', value: 11, img: design11  },
  { className: 'design-12', value: 12, img: design12  },
  { className: 'design-13', value: 13, img: design13 },
  { className: 'design-14', value: 14, img: design14  },
  { className: 'design-15', value: 15, img: design15  },
  { className: 'design-16', value: 16, img: design16  },
  { className: 'design-17', value: 17, img: design17  },
  { className: 'design-18', value: 18, img: design18  },
  { className: 'design-19', value: 19, img: design19  },
  { className: 'design-20', value: 20, img: design20  },
  { className: 'design-21', value: 21, img: design21  },
  { className: 'design-22', value: 22, img: design22  },
  { className: 'design-23', value: 23, img: design23  },
  { className: 'design-24', value: 24, img: design24  },
  { className: 'design-25', value: 25, img: design25  },
  { className: 'design-26', value: 26, img: design26  },
  { className: 'design-27', value: 27, img: design27  },
  { className: 'design-28', value: 28, img: design28  },
  { className: 'design-29', value: 29, img: design29  },
  { className: 'design-30', value: 30, img: design30  },
  { className: 'design-31', value: 31, img: design31  },
  { className: 'design-32', value: 32, img: design32  },
  { className: 'design-33', value: 3, img: design33  },
  { className: 'design-34', value: 4, img: design34  },
  { className: 'design-35', value: 35, img: design35  },
  { className: 'design-36', value: 36, img: design36  },
  { className: 'design-37', value: 37, img: design37  },
  { className: 'design-38', value: 38, img: design38  },
  { className: 'design-39', value: 39, img: design39  },
  { className: 'design-40', value: 40, img: design40  },
  { className: 'design-41', value: 41, img: design41  },
  { className: 'design-42', value: 42, img: design42  },
  { className: 'design-43', value: 43, img: design43  },
  { className: 'design-44', value: 44, img: design44  },
  { className: 'design-45', value: 45, img: design45  },
  { className: 'design-46', value: 46, img: design46  },
  { className: 'design-47', value: 47, img: design47  },
  { className: 'design-48', value: 48, img: design48},
];

export default designs;