import { createContext, useReducer } from "react";
import { useEffect } from "react";
import designs from "../data_structures/designs";
import { getImgByValue } from "../utils/functions";
import { getNextDesignIndex, getPrevDesignIndex } from "../utils/functions";

/* 
  TODO:
    - change the naming convetions with State variables. Maybe use STATES
*/

export const DesignsContext = createContext();

export const ACTIONS = {
  SET_DESIGN: "set_design",
  SET_DESIGN_NULL: "set_design_null",
  SET_DESIGN_NEXT: "set_design_next",
  SET_DESIGN_PREV: "set_design_prev",
};

export const STATES = {
  DESIGN_INDEX: "index",
  DESIGN_IMG: "img",
};

// Use reducer: https://www.youtube.com/watch?v=kK_Wqx3RnHk
// state is the previous state (second argument in useReducer, whatever it currently is)
// action is the object we pass into the dispatch function
export const designsReducer = (state, action) => {
  const newIndex = action.payload.index ? action.payload.index : null;
  console.log(
    `Design reducer action: ${action.type} || Payload: index: ${newIndex}`
  );

  switch (action.type) {
    case ACTIONS.SET_DESIGN:
      if (newIndex === null) {
        return {
          index: null,
          img: null,
        };
      }
      var design = getImgByValue(designs, newIndex);
      return {
        ...state,
        index: newIndex,
        img: design,
      };

    case ACTIONS.SET_DESIGN_NULL:
      return {
        index: null,
        img: null,
      };

    case ACTIONS.SET_DESIGN_NEXT:
      console.log("SETTING DESIGN to NEXT");
      var nextDesignIndex = getNextDesignIndex(newIndex);
      var nextImg = getImgByValue(designs, nextDesignIndex);
      return {
        index: nextDesignIndex,
        img: nextImg,
      };
    case ACTIONS.SET_DESIGN_PREV:
      console.log("SETTING DESIGN to PREV");
      var prevDesignIndex = getPrevDesignIndex(newIndex);
      var prevImg = getImgByValue(designs, prevDesignIndex);

      return {
        index: prevDesignIndex,
        img: prevImg,
      };

    default:
      return state;
  }
};

// children property represents whatever component/template DesignsContextProvider wraps
export const DesignsContextProvider = ({ children }) => {
  //useReducer similar to useState, but the way the state is updated is different

  const [state, dispatch] = useReducer(designsReducer, {
    index: null,
    design: null,
  });

  useEffect(() => {
    console.log("State has changed in designs context:", state);
  }, [state]);

  return (
    <DesignsContext.Provider value={{ ...state, dispatch }}>
      {children}
    </DesignsContext.Provider>
  );
};

/*

*/
