// Layout.js
import { motion } from 'framer-motion';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      {children} {/* This will be the page content */}
      <Footer />
    </motion.div>
  );
};

export default Layout;
