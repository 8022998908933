import backgroundVideo from "../img/backgrounds/andy-bg-clean-compressed.mp4";
import poster1 from "../img/backgrounds/poster-1-low-res.jpg";
// import poster2 from "../img/backgrounds/poster-2.png";
// import poster3 from "../img/backgrounds/poster-3.png";
// import poster4 from "../img/backgrounds/poster-4.png";
// import poster1lr from "../img/backgrounds/poster-1-low-res.jpg";
// import poster2lr from "../img/backgrounds/poster-2-low-res.jpg";
// import poster3lr from "../img/backgrounds/poster-3-low-res.jpg";
// import poster4lr from "../img/backgrounds/poster-4-low-res.jpg";
import { ImageWithBlurBackground } from "../utils/functions";

import React, { useState, useEffect, useRef } from "react";

const BackgroundVideo = () => {
  console.log("rendering video background");
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(true); // State to manage overlay visibility
  const [autoplayFailed, setAutoplayFailed] = useState(false);
  const videoRef = useRef(null);

   useEffect(() => {
    // Try to autoplay the video on mount
    const videoElement = videoRef.current;
    videoElement.play().then(() => {
      setVideoLoaded(true);
      setIsOverlayVisible(false);
    }).catch(() => {
      setAutoplayFailed(true); // Autoplay failed
    });

    // Event listeners for user interaction
    const handleUserInteraction = () => {
      const videoElement = videoRef.current;
  
      // Check if the video is ready to play
      if (videoElement.readyState >= 3) { // 3 = HAVE_FUTURE_DATA
        videoElement.play()
          .then(() => {
            setVideoLoaded(true);
            setIsOverlayVisible(false);
            setAutoplayFailed(false);
          })
          .catch((error) => {
            console.error("The video could not be played:", error);
          });
      } else {
        console.log("Video is not ready to play yet.");
      }
    };

    // If autoplay failed, add event listeners so user interaction can play the video
    if (autoplayFailed) {
      document.addEventListener('click', handleUserInteraction);
      document.addEventListener('touchstart', handleUserInteraction);
    }

    return () => {
      document.removeEventListener('click', handleUserInteraction);
      document.removeEventListener('touchstart', handleUserInteraction);
    };
  }, [autoplayFailed]);

  const overlayClass = isOverlayVisible ? 'video-overlay' : 'video-overlay-hidden';

  return (
    <div className="video-background">
      {(autoplayFailed || !videoLoaded) && <ImageWithBlurBackground img={{ img: poster1 }} />}
      <div className={overlayClass} />
      <video
        ref={videoRef}
        playsInline
        autoPlay
        loop
        muted
        onLoadedData={() => setVideoLoaded(true)}
        style={{ display: videoLoaded ? 'block' : 'none' }}
      >
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default BackgroundVideo;